import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';
import user from 'helpers/user';

const callTypes = {
  all: { label: 'All Calls', color: '#4cd137' },
  priority: { label: 'Priority Only', color: '#e89c18' },
  none: { label: 'None', color: '#e84118' },
};

class SupportCall extends Component {
  constructor() {
    super();
    this.state = { supportUsers: [], users: [], user: {}, loading: false };

    this.loadUser = this.loadUser.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.loadSupportUsers = this.loadSupportUsers.bind(this);
    this.handleAll = this.handleAll.bind(this);
    this.handlePriority = this.handlePriority.bind(this);
    this.handleNone = this.handleNone.bind(this);
    this.handleCalls = this.handleCalls.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderUser = this.renderUser.bind(this);
  }

  componentDidMount() {
    document.title = 'Support Calls | Flamingo Admin';
    this.loadUser();
    this.loadSupportUsers();
  }

  loadUser() {
    user.getCurrentUser().then((user) => this.setState({ user }));
  }

  loadSupportUsers() {
    this.setState({ loading: true });
    api.get('/support/call/status')
      .then((res) => this.setState({ supportUsers: res.data.data }, this.loadUsers))
      .catch(this.handleError);
  }

  loadUsers() {
    api.get('/support/users')
      .then((res) => this.setState({ users: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleAll() {
    this.handleCalls('all');
  }

  handlePriority() {
    this.handleCalls('priority');
  }

  handleNone() {
    this.handleCalls('none');
  }

  handleCalls(acceptingCalls) {
    this.setState({ loading: true });
    const { supportUsers, user } = this.state;
    const currentUser = supportUsers.find((u) => user.id === u.user.id);
    const data = { acceptingCalls };
    if (currentUser) {
      data.callPriority = currentUser.callPriority;
    } else {
      data.callPriority = 1;
    }
    api.post('/support/call/status', data)
      .then(this.handleUpdate)
      .catch(this.handleError);
  }

  handleUpdate(res) {
    const supportUser = res.data.data;
    const { supportUsers } = this.state;
    const userIndex = supportUsers.findIndex((u) => u.user.id === supportUser.user.id);
    if (userIndex) {
      supportUsers[userIndex] = supportUser;
    } else {
      supportUsers.unshift(supportUser);
    }
    this.setState({ supportUsers, loading: false });
  }

  handleError(e) {
    let error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    if (error === 'validation' && window.access(() => e.response.data.validation[0])) {
      error = `Invalid ${e.response.data.validation[0]}`;
    }
    this.setState({ error, loading: false });
  }

  renderUser(user, i) {
    const { supportUsers } = this.state;
    const supportUser = supportUsers.find((s) => user.id === s.user.id) || { acceptingCalls: 'none', callPriority: 1 };
    return (
      <div className="fm-support-call-user" key={i}>
        <div className="fm-support-call-user-status" style={{ backgroundColor: callTypes[supportUser.acceptingCalls].color }}></div>
        <p className="fm-support-call-user-name">{ user.firstName } { user.lastName }</p>
        <p className="fm-support-call-user-accepting">{ callTypes[supportUser.acceptingCalls].label }</p>
      </div>
    );
  }

  render() {
    const { supportUsers, users, user, loading, error } = this.state;
    const currentUser = supportUsers.find((u) => user.id === u.user.id);
    const acceptingCalls = currentUser ? currentUser.acceptingCalls : false;

    return (
      <div className="fm-support-call">
        <NavigationBar title="Support Calls" loading={loading} showBack={true} />
        <div className="fm-support-call-status">
          <p className="fm-support-call-title">Accepting Calls</p>
          <div className="fm-support-call-status-options">
            <button disabled={loading} className={ acceptingCalls === 'all' ? 'fm-support-call-status-option-active' : 'fm-support-call-status-option' } onClick={this.handleAll}>All Calls</button>
            <button disabled={loading} className={ acceptingCalls === 'priority' ? 'fm-support-call-status-option-active' : 'fm-support-call-status-option' } onClick={this.handlePriority}>Priority Only</button>
            <button disabled={loading} className={ acceptingCalls === 'none' ? 'fm-support-call-status-option-active' : 'fm-support-call-status-option' } onClick={this.handleNone}>None</button>
          </div>
        </div>
        <div className="fm-support-call-users">
        <p className="fm-support-call-title">All Support Users</p>
          <div className="fm-support-call-users-list">
            { users.map(this.renderUser) }
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default SupportCall;
