import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import history from 'helpers/history';
import auth from 'helpers/auth';
import location from 'helpers/location';
import nfc from 'helpers/nfc';

import TabBar from 'components/common/tab-bar';
import Banner from 'components/common/banner';
import Loading from 'components/common/loading';

import Vehicles from 'components/vehicles';
import Vehicle from 'components/vehicle';
import Staff from 'components/staff';
import User from 'components/user';
import Users from 'components/users';
import Task from 'components/task';
import Nest from 'components/nest';
import Insights from 'components/insights';
import Analytics from 'components/analytics';
import ChangeRegion from 'components/change-region';
import Statistics from 'components/statistics';
import Inspection from 'components/inspection';
import Firmware from 'components/firmware';
import DataSharing from 'components/data-sharing';
import Maintenance from 'components/maintenance';
import Performance from 'components/performance';
import Support from 'components/support';
import Parking from 'components/parking';
import StripeUnblock from 'components/stripe-unblock';
import More from 'components/more';
import Ticket from 'components/ticket';
import HelmetHires from 'components/helmet-hires';
import HelmetSelfies from 'components/helmet-selfies';
import VehicleTheft from 'components/vehicle-theft';
import Trip from 'components/trip';
import PlanApplications from 'components/plan-applications';
import Forecast from 'components/forecast';
import AddVehicle from 'components/add-vehicle';
import ReplaceIot from 'components/replace-iot';
import Hub from 'components/hub';
import HelmetLock from 'components/helmet-lock';
import Subscription from 'components/subscription';
import Incident from 'components/incident';
import Dashboard from 'components/dashboard';
import Zone from 'components/zone';
import Report from 'components/report';
import Battery from 'components/battery';
import Call from 'components/call';
import VehicleBurnouts from 'components/vehicle-burnouts';
import VehicleSounds from 'components/vehicle-sounds';
import Warehouses from 'components/warehouses';
import Manual from 'components/manual';
import Instruction from 'components/instruction';
import Schedule from 'components/schedule';
import VehicleIdentifiers from 'components/vehicle-identifiers';
import VehicleLocator from 'components/vehicle-locator';
import Region from 'components/region';
import Voucher from 'components/voucher';
import Scan from 'components/scan';
import AddBeam from 'components/add-beam';

class Platform extends Component {
  constructor() {
    super();
    this.state = { loading: true };
  }

  componentDidMount() {
    auth.init((authed) => authed ? this.setState({ loading: false }) : history.push('/login'));
    location.refreshLocation();
    nfc.startScanner();
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div className="fm-platform">
        <Banner />
        <div className="fm-platform-content">
          <div className="fm-platform-switch">
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/vehicle/add/beam" component={AddBeam} />
              <Route path="/vehicle/add" component={AddVehicle} />
              <Route path="/vehicle/:vehicleId/replace-iot" component={ReplaceIot} />
              <Route path="/vehicle/:vehicleId/firmware" component={Firmware} />
              <Route path="/vehicle/:vehicleId/burnouts" component={VehicleBurnouts} />
              <Route path="/vehicle/:vehicleId/sounds" component={VehicleSounds} />
              <Route path="/vehicle/:vehicleId/identifiers" component={VehicleIdentifiers} />
              <Route path="/vehicle/:vehicleId/locate" component={VehicleLocator} />
              <Route path="/vehicle/:vehicleId/theft/:statusChangeId?" component={VehicleTheft} />
              <Route path="/vehicle/:vehicleId" component={Vehicle} />
              <Route path="/vehicles/:filter?" component={Vehicles} />
              <Route path="/inspection" component={Inspection} />
              <Route path="/user" component={User} />
              <Route path="/users/:query?" component={Users} />
              <Route path="/staff" component={Staff} />
              <Route path="/task" component={Task} />
              <Route path="/nest" component={Nest} />
              <Route path="/insights" component={Insights} />
              <Route path="/trip" component={Trip} />
              <Route path="/analytics" component={Analytics} />
              <Route path="/statistics" component={Statistics} />
              <Route path="/data-sharing" component={DataSharing} />
              <Route path="/change-region/:status?" component={ChangeRegion} />
              <Route path="/maintenance" component={Maintenance} />
              <Route path="/performance" component={Performance} />
              <Route path="/support" component={Support} />
              <Route path="/parking" component={Parking} />
              <Route path="/more" component={More} />
              <Route path="/ticket" component={Ticket} />
              <Route path="/helmet-hires" component={HelmetHires} />
              <Route path="/helmet-selfies" component={HelmetSelfies} />
              <Route path="/helmet-lock/:helmetLockId" component={HelmetLock} />
              <Route path="/plan-applications" component={PlanApplications} />
              <Route path="/forecast" component={Forecast} />
              <Route path="/hub" component={Hub} />
              <Route path="/incident" component={Incident} />
              <Route path="/subscription" component={Subscription} />
              <Route path="/stripe/unblock/:fingerprint" component={StripeUnblock} />
              <Route path="/call/:phone" component={Call} />
              <Route path="/zone" component={Zone} />
              <Route path="/report" component={Report} />
              <Route path="/battery" component={Battery} />
              <Route path="/warehouses" component={Warehouses} />
              <Route path="/manual" component={Manual} />
              <Route path="/instruction" component={Instruction} />
              <Route path="/schedule" component={Schedule} />
              <Route path="/region" component={Region} />
              <Route path="/voucher" component={Voucher} />
              <Route path="/scan" component={Scan} />
              <Redirect from='/' to='/vehicles' />
            </Switch>
          </div>
          <TabBar path={this.props.location.pathname} />
        </div>
      </div>
    );
  }
}

export default Platform;
