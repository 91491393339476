import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Tickets from './components/tickets';

import SupportTicket from './components/support-ticket/';
import SupportActivity from './components/support-activity/';
import SupportCall from './components/support-call/';

class Support extends Component {
  render() {
    return (
      <Switch>
        <Route path="/support/activity" component={SupportActivity} />
        <Route path="/support/call" component={SupportCall} />
        <Route path="/support/ticket/:ticketId" component={SupportTicket} />
        <Route path="/support/:tab?" component={Tickets} />
      </Switch>
    );
  }
}

export default Support;
