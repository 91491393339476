import React, { Component } from 'react';
import { QrReader } from '@flamingo-scooters/react-qr-reader';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

class AddVehicle extends Component {
  constructor() {
    super();
    this.state = { vehicle: { beamQrCode: '', qrCode: '', registration: '' }, loading: false };

    this.change = this.change.bind(this);
    this.keyDown = this.keyDown.bind(this);

    this.handleScan = this.handleScan.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleError = this.handleError.bind(this);

    this.inputBeamQr = React.createRef();
    this.inputQr = React.createRef();
    this.inputRegistration = React.createRef();
  }

  componentDidMount() {
    document.title = 'Add Beam | Flamingo Admin';
    this.inputBeamQr.current.focus();
  }

  handleScan(result, error) {
    const { vehicle, loading } = this.state;
    if (result && !loading) {
      const flamingoPattern = /^https:\/\/ride\.flamingoscooters\.com\/s\/\d{6}$/;
      const beamPattern = /^https:\/\/deeplink\.ridebeam\.com\/?\?code=[a-zA-Z0-9]{5,7}$/;
      if (flamingoPattern.test(result.text)) {
        const qrCode = result.text.slice(-6);
        if (!isNaN(qrCode) && vehicle.qrCode !== qrCode) {
          vehicle.qrCode = qrCode;
          this.setState({ vehicle });
        }
      } else if (beamPattern.test(result.text)) {
        const beamQrCode = result.text.split('=').pop();
        if (vehicle.beamQrCode !== beamQrCode) {
          vehicle.beamQrCode = beamQrCode;
          this.setState({ vehicle });
        }
      }
    }
  }

  handleAdd() {
    this.setState({ loading: true, error: false });
    const vehicle = Object.assign({}, this.state.vehicle);

    if (vehicle.beamQrCode.length < 5 || vehicle.beamQrCode.length > 7) {
      return this.setState({ loading: false, error: 'Invalid Beam QR Code' });
    }

    if (vehicle.qrCode.length !== 6 || !/^\d+$/.test(vehicle.qrCode)) {
      return this.setState({ loading: false, error: 'Invalid QR code' });
    }

    if (vehicle.registration.length !== 4 || !/^\d+$/.test(vehicle.registration)) {
      return this.setState({ loading: false, error: 'Invalid registration' });
    }

    return api.post('/vehicle/beam', vehicle)
      .then((res) => history.push(`/vehicle/${res.data.data.id}`))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  change(key, e) {
    const vehicle = this.state.vehicle;
    vehicle[key] = e.target.value;
    this.setState({ vehicle });
  }

  keyDown(key, e) {
    if (e.key === 'Enter') {
      switch (key) {
        case 'beamQrCode':
          return this.inputQr.current.focus();
        case 'qrCode':
          return this.inputRegistration.current.focus();
        default:
          this.handleAdd();
      }
    }
  }

  render() {
    const { loading, vehicle, error } = this.state;
    const { beamQrCode, qrCode, registration } = vehicle;
    const helpAction = { to: { pathname: `/manual/67` }, icon: 'info-circle' };

    return (
      <div className="fm-beam">
        <NavigationBar title="Add Beam" loading={loading} rightActions={[helpAction]} />
        <div className="fm-beam-content">
          <QrReader
            scanDelay={300}
            onResult={this.handleScan}
            constraints={{ facingMode: 'environment' }}
            videoStyle={{ objectFit: 'cover' }}
            className="fm-beam-scanner"
          />
          <div className="fm-beam-input">
            <p className="fm-input-label">Beam QR Code</p>
            <input ref={this.inputBeamQr} value={beamQrCode} className="fm-input" onChange={(e) => this.change('beamQrCode', e)} onKeyDown={(e) => this.keyDown('beamQrCode', e)} />
          </div>
          <div className="fm-beam-input">
            <p className="fm-input-label">QR Code</p>
            <input ref={this.inputQr} value={qrCode} className="fm-input" onChange={(e) => this.change('qrCode', e)} onKeyDown={(e) => this.keyDown('qrCode', e)} pattern="\d*" maxLength={6} />
          </div>
          <div className="fm-beam-input">
            <p className="fm-input-label">Registration</p>
            <input ref={this.inputRegistration} value={registration} className="fm-input" onChange={(e) => this.change('registration', e)} onKeyDown={(e) => this.keyDown('registration', e)} pattern="\d*" maxLength={4} />
          </div>
          <button className="fm-task-new-button" onClick={this.handleAdd}>{ loading ? 'Loading...' : 'Add Vehicle' }</button>
        </div>
        { error && <Toast>{ error }</Toast> }
      </div>
    );
  }
}

export default AddVehicle;
