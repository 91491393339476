import Promise from 'bluebird';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signInWithPhoneNumber, signInWithCredential, updatePassword, signOut, linkWithCredential, RecaptchaVerifier, EmailAuthProvider } from "firebase/auth";
import api from './api';
import user from './user';

const config = {
  apiKey: 'AIzaSyDxnj9p7zl2Ivbjkcxhc3Vgeff7Ubf7SUw',
  authDomain: 'flamingoscooters.firebaseapp.com',
  databaseURL: 'https://flamingoscooters.firebaseio.com',
  projectId: 'flamingoscooters',
  storageBucket: 'flamingoscooters.appspot.com',
  messagingSenderId: '663814096530',
  appId: '1:663814096530:web:c8de36483009ce61',
};

const checkConfig = {
  provider: new ReCaptchaV3Provider('6Lc_ZzUmAAAAAGXTew_cYUtNqEoVNUe3D3Ez1bxq'),
  isTokenAutoRefreshEnabled: true
};

/* ----- Debugging Token ----- */
if (window.location.hostname === 'localhost') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const app = initializeApp(config);
initializeAppCheck(app, checkConfig);
const auth = getAuth();

const init = (cb) => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    unsubscribe();
    cb(user ? true : false);
  });
}

const logout = () => {
  return signOut(auth);
};

const login = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then(verifyAccount);
};

const loginWithPhoneNumber = (phone, verifier) => {
  return signInWithPhoneNumber(auth, phone, verifier);
};

const loginWithCredential = (credential) => {
  return signInWithCredential(auth, credential);
};

const joinWithCredential = (credential) => {
  return linkWithCredential(auth.currentUser, credential);
};

const setPassword = (password) => {
  return updatePassword(auth.currentUser, password);
};

const credentialFromEmail = (email, password) => {
  return EmailAuthProvider.credential(email, password);
}

const verifyAccount = (firebaseUser) => {
  return api.get('/user')
    .then((res) => user.updateUser(res.data.data))
    .catch(() => Promise.reject({ code: 'auth/not-authorized' }));
};

const getIdToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe();
      user.getIdToken(false).then(resolve).catch(reject);
    }, reject);
  });
};

const getUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

const authHelper = { getAuth, init, logout, login, loginWithPhoneNumber, joinWithCredential, loginWithCredential, credentialFromEmail, setPassword, getIdToken, getUser, RecaptchaVerifier };

export default authHelper;
