import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      working: [],
      schedule: [],
      loading: true,
    };

    this.loadSchedule = this.loadSchedule.bind(this);
    this.parseSchedule = this.parseSchedule.bind(this);
    this.handleError = this.handleError.bind(this);
    this.renderWorking = this.renderWorking.bind(this);
    this.renderEvent = this.renderEvent.bind(this);
  }

  componentDidMount() {
    document.title = 'Team Schedule | Flamingo Admin';
    this.loadSchedule();
  }

  // Networking

  loadSchedule() {
    return api.get('/performance/schedule')
      .then(this.parseSchedule)
      .catch(this.handleError);
  }

  parseSchedule(res) {
    const working = res.data.data.working;
    const schedule = res.data.data.schedule.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
    this.setState({ working, schedule, loading: false })
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  // Renders

  renderWorking(user, i) {
    return (
      <div className="fm-schedule-working" key={i}>
        <div className="fm-schedule-working-details">
          <p className="fm-schedule-working-name">{ user.firstName } { user.lastName }</p>
          <p className="fm-schedule-working-job">{ user.timesheet.job_type === 'regular' ? 'Active' : 'On Break' }{ user.region ? ` - ${user.region.name}` : null }</p>
        </div>
        <div className="fm-schedule-working-timing">
          <div className="fm-schedule-working-status" style={{ backgroundColor: user.timesheet.job_type === 'regular' ? '#4cd137' : '#fbc531' }}></div>
          <p className="fm-schedule-working-timing-hours">{ moment(user.timesheet.start).fromNow(true) }</p>
        </div>
      </div>
    );
  }

  renderEvent(event, i) {
    const newDay = i === 0 || !moment(this.state.schedule[i - 1].start).isSame(event.start, 'day');
    return (
      <div className="fm-schedule-event" key={i}>
        {
          newDay && <p className="fm-schedule-event-heading">{ moment(event.start).format('dddd, D MMMM') }</p>
        }
        <div className="fm-schedule-event-card" style={{ backgroundColor: event.color }}>
          <div className="fm-schedule-event-details">
            <p className="fm-schedule-event-title">{ event.job ? event.job.name : 'Shift' }</p>
            <p className="fm-schedule-event-time">{ moment(event.start).format('h:mm a') } - { moment(event.end).format('h:mm a, ddd D MMM') }{ event.region ? ` - ${event.region.name}` : null }</p>
          </div>
          <div className="fm-schedule-event-user">
            <p className="fm-schedule-event-user-initials">{ event.user.firstName.charAt(0) + event.user.lastName.charAt(0) }</p>
            <p className="fm-schedule-event-user-full">{ event.user.firstName } { event.user.lastName }</p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { loading, schedule, working, error } = this.state;

    return (
      <div className="fm-schedule">
        <NavigationBar title="Team Schedule" loading={loading} />
        <div className="fm-schedule-content">
          {
            working.length > 0 &&
            <div className="fm-schedule-container">
              <p className="fm-schedule-heading">Who's Working</p>
              { working.map(this.renderWorking) }
            </div>
          }
          {
            schedule.length > 0 &&
            <div className="fm-schedule-container">
              <p className="fm-schedule-heading">Schedule</p>
              { schedule.map(this.renderEvent) }
            </div>
          }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default Schedule;
