import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

const models = {
  'es': 'ES',
  'max': 'Max',
  'max-pro': 'Max Pro',
  'max-plus': 'Max Plus',
  'max-plus-x': 'Max Plus X',
  's90l': 'S90L',
  'a200': 'A200',
};

class MaintenanceSolution extends Component {
  constructor(props) {
    super(props);
    const preloadedSolution = props.location.state ? props.location.state.solution : false;
    this.state = { solution: preloadedSolution || {}, loading: !preloadedSolution, error: false };

    this.loadSolution = this.loadSolution.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = `Solution | Flamingo Admin`;
    this.loadSolution();
  }

  /* NETWORKING */

  loadSolution() {
    if (this.state.solution.id) {
      return;
    }
    this.setState({ loading: true });
    api.get(`/maintenance/solution/${this.props.match.params.solutionId}`)
      .then((res) => this.setState({ solution: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  /* HANDLERS */

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* RENDERS */

  render() {
    const { solution, loading, error } = this.state;

    let title = 'Solution';
    if (solution.fault && solution.model) {
      title = `${models[solution.model]} ${solution.fault}`
    }

    return (
      <div className="fm-maintenance-solution">
        <NavigationBar title={title} loading={loading} showBack={true} />
        <div className="fm-maintenance-solution-container">
          { solution.name && <p className="fm-maintenance-solution-name">{ solution.name }</p> }
          { solution.debugField &&
            <>
              <p className="fm-maintenance-solution-subtitle">Out in the Field Solution</p>
              <p className="fm-maintenance-solution-text">{ solution.debugField }</p>
            </>
          }
          { solution.debugField &&
            <>
              <p className="fm-maintenance-solution-subtitle">Repair Solution</p>
              <p className="fm-maintenance-solution-text">{ solution.debugWarehouse }</p>
            </>
          }
          { solution.imageUrl &&
            <>
              <p className="fm-maintenance-solution-subtitle">Segway Solution</p>
              <img className="fm-maintenance-solution-img" alt="Solution" src={ solution.imageUrl } />
            </>
          }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default MaintenanceSolution;
