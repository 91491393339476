import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Icon from 'components/common/icon';
import api from 'helpers/api';
import colors from 'helpers/colors';

const guides = [
  { name: 'A200', value: 'a200', icon: 'https://storage.googleapis.com/flamingo-static/images/admin/a200.png' },
  { name: 'S90L', value: 's90l', icon: 'https://storage.googleapis.com/flamingo-static/images/admin/vehicle-s90l.png?v2' },
  { name: 'Max Plus X', value: 'max-plus-x', icon: 'https://storage.googleapis.com/flamingo-static/images/admin/vehicle-max-plus-x.png?v2' },
  { name: 'Max Plus', value: 'max-plus', icon: 'https://storage.googleapis.com/flamingo-static/images/admin/vehicle-max-plus.png' },
  { name: 'Max Pro', value: 'max-pro', icon: 'https://storage.googleapis.com/flamingo-static/images/admin/vehicle-max-pro.png' },
  { name: 'Max', value: 'max', icon: 'https://storage.googleapis.com/flamingo-static/images/admin/vehicle-max.png' },
];

class InspectionSchedule extends Component {
  constructor() {
    super();
    this.state = { schedule: [], loading: true };

    this.loadSchedule = this.loadSchedule.bind(this);
    this.renderGuide = this.renderGuide.bind(this);
    this.renderVehicle = this.renderVehicle.bind(this);
  }

  componentDidMount() {
    document.title = 'Inspection Schedule | Flamingo Admin';
    this.loadSchedule();
  }

  loadSchedule() {
    this.setState({ loading: true });
    api.get('/inspection/schedule')
      .then((res) => this.setState({ schedule: res.data.data, loading: false }))
      .catch((res) => this.setState({ loading: false }));
  }

  /* RENDERS */

  renderGuide(guide, i) {
    return (
      <Link className="fm-inspection-schedule-guide" key={i} to={`/maintenance/guides/${guide.value}`}>
        <div className="fm-inspection-schedule-guide-icon" style={{ backgroundImage: `url('${guide.icon}')` }}></div>
        <p className="fm-inspection-schedule-guide-name">{ guide.name } Repair Guides</p>
      </Link>
    );
  }

  renderVehicle(vehicle, i) {
    const daysSince = moment().diff(vehicle.lastInspection, 'days');
    return (
      <div className="fm-inspection-schedule-item"  key={i}>
        <Link className="fm-inspection-schedule-item-link" to={`/vehicle/${vehicle.id}`}>
          <div className="fm-inspection-schedule-item-vehicle">
            <p className="fm-inspection-schedule-item-rego">{ vehicle.registration }</p>
            <p className="fm-inspection-schedule-item-status" style={{ backgroundColor: colors.status[vehicle.status] }}>{ vehicle.status.toUpperCase() }</p>
          </div>
          <p className="fm-inspection-schedule-item-inspection">{ vehicle.lastInspection ? `Last inspection ${daysSince} days ago` : 'Never inspected' }</p>
        </Link>
        <Link className="fm-inspection-schedule-item-button" to={`/task/new/${vehicle.id}/inspection`}>Create Task<Icon icon="chevron-right" /></Link>
      </div>
    );
  }

  render() {
    const { loading, schedule } = this.state;
    const refreshAction = { onClick: this.loadSchedule, icon: 'sync-alt' };

    return (
      <div className="fm-inspection-schedule">
        <NavigationBar title="Inspection Schedule" loading={loading} rightActions={[refreshAction]} />
        <div className="fm-inspection-schedule-guides">
          { guides.map(this.renderGuide) }
        </div>
        <div className="fm-inspection-schedule-list">
          { schedule.map(this.renderVehicle) }
        </div>
      </div>
    );
  }
}

export default InspectionSchedule;
