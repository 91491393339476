import Promise from 'bluebird';

import api from './api';

let agentCache = null;
let regionCache = null;
let templateCache = null;

function getAgents() {
  if (agentCache) {
    return Promise.resolve(agentCache);
  }

  return api.get('/support/users')
    .then((res) => {
      agentCache = res.data.data.map((s) => ({ label: `${s.firstName} ${s.lastName}`, value: s.id, icon: 'user' }));
      return agentCache;
    });
}

function getRegions() {
  if (regionCache) {
    return Promise.resolve(regionCache);
  }

  return api.get('/region/all')
    .then((res) => {
      regionCache = res.data.data.map((r) => ({ label: r.name, value: r.id, icon: 'map-marker' }));
      return regionCache;
    });
}

function getTemplates() {
  if (templateCache) {
    return Promise.resolve(templateCache);
  }

  return api.get('/support/templates')
    .then((res) => {
      templateCache = res.data.data;
      return templateCache;
    });
}

const supportCache = {
  getAgents,
  getRegions,
  getTemplates,
};

export default supportCache;
