import React, { Component } from 'react';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import history from 'helpers/history';
import api from 'helpers/api';

const voucherTypes = [
  { value: 'MINUTES', label: 'Minutes' },
  { value: 'UNLOCKS', label: 'Unlocks' },
  { value: 'CREDIT', label: 'Credit' },
];

const voucherTypeValueLabels = {
  MINUTES: { label: 'Voucher Minutes', hint: 'Total minutes, for example enter "10" for 10 minutes.' },
  UNLOCKS: { label: 'Voucher Unlocks', hint: 'Total unlocks, for example enter "10" for 10 unlocks.' },
  CREDIT: { label: 'Voucher Credit (Dollars)', hint: 'Total credit, full numbers only, for example enter "10" for $10.' },
};

const voucherDurations = [
  { value: 86400, label: '1 Day' },
  { value: 604800, label: '1 Week' },
  { value: 1209600, label: '2 Weeks' },
  { value: 2628000, label: '1 Month' },
  { value: 7884000, label: '3 Months' },
  { value: 15768000, label: '6 Months' },
  { value: 31536000, label: '1 Year' },
];

const voucherNewUsers = [
  { value: 0, label: 'All Users' },
  { value: 1, label: 'New Users Only' },
];

const voucherBypassPayment = [
  { value: 0, label: 'Requires Payment Method' },
  { value: 1, label: 'Bypass Payment Method' },
];

const voucherVehicleType = [
  { value: 'null', label: 'All Vehicles' },
  { value: 'Scooter', label: 'Scooters Only' },
  { value: 'BIKE', label: 'Bikes Only' },
];

class VoucherNew extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: false,
      voucher: {
        type: 'MINUTES',
        value: 10,
        duration: 1209600,
        code: '',
        expires: moment().add(2, 'weeks').format('YYYY-MM-DD'),
        newUsersOnly: 0,
        bypassPaymentMethod: 0,
        maxRedemptions: 1000,
        total: 1,
        notes: '',
      },
      regions: [],
    };

    this.loadRegions = this.loadRegions.bind(this);
    this.createVoucher = this.createVoucher.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    document.title = 'New Voucher | Flamingo Admin';
    this.loadRegions();
  }

  loadRegions() {
    return api.get(`/region/all`)
      .then((res) => this.setState({ regions: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  createVoucher() {
    this.setState({ loading: true, error: false });
    const { voucher } = this.state;
    const voucherData = {
      type: voucher.type,
      value: parseInt(voucher.value) * (voucher.type === 'CREDIT' ? 100 : 1),
      duration: parseInt(voucher.duration),
      expires: moment(voucher.expires).endOf('day').format('YYYY-MM-DDTHH:mm'),
      code: voucher.code,
      newUsersOnly: parseInt(voucher.newUsersOnly) === 1,
      bypassPaymentMethod: parseInt(voucher.bypassPaymentMethod) === 1,
      maxRedemptions: parseInt(voucher.maxRedemptions),
      notes: voucher.notes,
      total: parseInt(voucher.total),
    };

    if (voucher.regionId && voucher.regionId !== 'null') {
      voucherData.regionId = parseInt(voucher.regionId);
    }

    if (voucher.vehicleType && voucher.vehicleType !== 'null') {
      voucherData.vehicleType = voucher.vehicleType;
    }

    return api.post('/voucher', voucherData)
      .then((res) => history.goBack())
      .catch(this.handleError);
  }

  handleError(e) {
    const validation = window.access(() => e.response.data.validation[0]) ? `Invalid ${e.response.data.validation[0]}` : false;
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error: validation || error, loading: false });
  }

  onChange(e) {
    const voucher = this.state.voucher;
    voucher[e.target.getAttribute('okey')] = e.target.value;
    this.setState({ voucher, error: false })
  }

  render() {
    const { loading, regions, error, voucher } = this.state;

    return (
      <div className="fm-voucher-new">
        <NavigationBar title="Create Voucher" showBack={true} loading={loading} />
        <div className="fm-voucher-new-form">
          <div className="fm-voucher-new-input">
            <p className="fm-input-label">Voucher Type</p>
            <select value={voucher.type} disabled={loading} onChange={this.onChange} okey="type" className="fm-input fm-input-select">
              { voucherTypes.map((voucherType, i) => <option value={voucherType.value} key={i}>{ voucherType.label }</option>) }
            </select>
          </div>
          <div className="fm-voucher-new-input">
            <p className="fm-input-label">{ voucherTypeValueLabels[voucher.type].label }</p>
            <input value={voucher.value} onChange={this.onChange} okey="value" className="fm-input" type="number" disabled={loading} />
            <p className="fm-input-hint">{ voucherTypeValueLabels[voucher.type].hint }</p>
          </div>
          <div className="fm-voucher-new-input">
            <p className="fm-input-label">Voucher Duration</p>
            <select value={voucher.duration} disabled={loading} onChange={this.onChange} okey="duration" className="fm-input fm-input-select">
              { voucherDurations.map((voucherDuration, i) => <option value={voucherDuration.value} key={i}>{ voucherDuration.label }</option>) }
            </select>
            <p className="fm-input-hint">The duration the user has to use the voucher once redeemed.</p>
          </div>
          <div className="fm-voucher-new-input">
            <p className="fm-input-label">Voucher Expiration</p>
            <input value={voucher.expires} onChange={this.onChange} okey="expires" className="fm-input" type="date" disabled={loading} />
            <p className="fm-input-hint">The last day the voucher can be redeemed (inclusive).</p>
          </div>
          <div className="fm-voucher-new-input">
            <p className="fm-input-label">Voucher Code</p>
            <input value={voucher.code} onChange={this.onChange} okey="code" className="fm-input" type="text" disabled={loading} />
            <p className="fm-input-hint">Voucher code must be unique, using capital letters and numbers only, no spaces or punctuation. Maximum 16 characters or 12 characters for bulk vouchers.</p>
          </div>
          <div className="fm-voucher-new-input">
            <p className="fm-input-label">New Users Only</p>
            <select value={voucher.newUsersOnly} disabled={loading} onChange={this.onChange} okey="newUsersOnly" className="fm-input fm-input-select">
              { voucherNewUsers.map((option, i) => <option value={option.value} key={i}>{ option.label }</option>) }
            </select>
            <p className="fm-input-hint">If the voucher can only be redeemed by users who have not yet taken a trip.</p>
          </div>
          <div className="fm-voucher-new-input">
            <p className="fm-input-label">Bypass Payment Method</p>
            <select value={voucher.bypassPaymentMethod} disabled={loading} onChange={this.onChange} okey="bypassPaymentMethod" className="fm-input fm-input-select">
              { voucherBypassPayment.map((option, i) => <option value={option.value} key={i}>{ option.label }</option>) }
            </select>
            <p className="fm-input-hint">If the voucher will allow the user to skip adding a payment method before starting a trip. Default requires payment method.</p>
          </div>
          <div className="fm-voucher-new-input">
            <p className="fm-input-label">Region</p>
            <select value={voucher.regionId || 'null'} disabled={loading} onChange={this.onChange} okey="regionId" className="fm-input fm-input-select">
              <option value="null">All Regions</option>
              { regions.map((region, i) => <option value={region.id} key={i}>{ region.name }</option>) }
            </select>
            <p className="fm-input-hint">If the voucher can only be used in a specific region.</p>
          </div>
          <div className="fm-voucher-new-input">
            <p className="fm-input-label">Vehicle Type</p>
            <select value={voucher.vehicleType} defaultValue="null" disabled={loading} onChange={this.onChange} okey="vehicleType" className="fm-input fm-input-select">
              { voucherVehicleType.map((type, i) => <option value={type.value} key={i}>{ type.label }</option>) }
            </select>
            <p className="fm-input-hint">If the voucher can only be used on a specific vehicle type.</p>
          </div>
          <div className="fm-voucher-new-input">
            <p className="fm-input-label">Maximum Redemptions</p>
            <input value={voucher.maxRedemptions} onChange={this.onChange} okey="maxRedemptions" className="fm-input" type="number" disabled={loading} />
            <p className="fm-input-hint">The maximum times the voucher can be redeemed.</p>
          </div>
          <div className="fm-voucher-new-input">
            <p className="fm-input-label">Total Vouchers</p>
            <input value={voucher.total} onChange={this.onChange} okey="total" className="fm-input" type="number" disabled={loading} />
            <p className="fm-input-hint">The total number of vouchers to generate. If the total is greater than one, a four digit value will be assigned to the end of each code. Only use this for bulk voucher creation.</p>
          </div>
          <div className="fm-voucher-new-input">
            <p className="fm-input-label">Notes</p>
            <input value={voucher.notes} onChange={this.onChange} okey="notes" className="fm-input" type="text" disabled={loading} />
            <p className="fm-input-hint">A short description of what the voucher is being used for.</p>
          </div>
          <button className="fm-voucher-new-button" onClick={this.createVoucher} disabled={loading}>{ loading ? 'Loading...' : 'Create Voucher' }</button>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default VoucherNew;
