import React, { Component } from 'react';
import { QrReader } from '@flamingo-scooters/react-qr-reader';
import NavigationBar from 'components/common/navigation-bar';
import api from 'helpers/api';

const scanStatuses = {
  ready: { label: 'Ready to Scan', color: '#4cd137' },
  uploaded: { label: 'Uploaded Code', color: '#4cd137' },
  scanned: { label: 'Scanned Code', color: '#e89c18' },
  error: { label: 'Upload Error', color: '#e84118' },
  invalid: { label: 'Invalid Code', color: '#e84118' },
};

class Scan extends Component {
  constructor() {
    super();
    this.state = { status: 'ready', loading: false };
    this.handleScan = this.handleScan.bind(this);
  }

  componentDidMount() {
    document.title = 'Scan | Flamingo Admin';
  }

  handleScan(result, error) {
    if (result) {
      if (result.text.includes('https://deeplink.ridebeam.com/?code') || result.text.includes('https://deeplink.ridebeam.com?code')) {
        const qrCode = result.text.split('=').pop();
        if (this.state.lastScan !== qrCode) {
          this.setState({ status: 'scanned', lastScan: qrCode, lastUpload: qrCode, loading: true });
          return api.post('/scan', { value: qrCode })
            .then(() => this.setState({ status: 'uploaded', loading: false }))
            .catch(() => this.setState({ status: 'error', lastScan: false, loading: false }));
        }
      } else {
        this.setState({ status: 'invalid', lastScan: false, loading: false });
      }
    }
  }

  render() {
    const { loading, status, lastUpload } = this.state;
    const scanStatus = scanStatuses[status];

    return (
      <div className="fm-scan">
        <NavigationBar title="Scan" loading={loading} />
        <QrReader
          scanDelay={300}
          onResult={this.handleScan}
          constraints={{ facingMode: 'environment' }}
          videoStyle={{ objectFit: 'cover' }}
          className="fm-scan-reader"
        />
        <div className="fm-scan-bar">
          <div className="fm-scan-bar-status" style={{ backgroundColor: scanStatus.color }}></div>
          <p className="fm-scan-bar-label">{ scanStatus.label }{ lastUpload ? ` - ${lastUpload}` : null }</p>
        </div>
      </div>
    );
  }
}

export default Scan;
