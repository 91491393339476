import React, { Component } from 'react';
import NavigationBar from 'components/common/navigation-bar';
import Toast from 'components/common/toast';
import api from 'helpers/api';

class VehicleIdentifiers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleId: props.match.params.vehicleId,
      identifiers: {},
      loading: true,
    };

    this.loadVehicleIdentifiers = this.loadVehicleIdentifiers.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
  }

  componentDidMount() {
    document.title = 'Vehicle Identifiers | Flamingo Admin';
    this.loadVehicleIdentifiers();
  }

  /* NETWORKING */

  loadVehicleIdentifiers() {
    this.setState({ loading: true });
    return api.get(`/vehicle/${this.state.vehicleId}/identifiers`)
      .then((res) => this.setState({ identifiers: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  /* HANDLERS */

  handleCopy(e) {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = e.target.innerText;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }

  /* RENDERS */

  render() {
    const {
      loading,
      identifiers,
      error,
    } = this.state;

    const title = identifiers.registration ? `${identifiers.registration} - Vehicle Identifiers` : 'Vehicle Identifiers';

    return (
      <div className="fm-vehicle-identifiers">
        <NavigationBar title={title} loading={loading} showBack={true} />
        <div className="fm-vehicle-identifiers-details">
          <div className="fm-vehicle-identifiers-row">
            <p className="fm-vehicle-identifiers-row-label">Flamingo ID</p>
            <p className="fm-vehicle-identifiers-row-value" onClick={this.handleCopy}>{ identifiers.id || '' }</p>
          </div>
          <div className="fm-vehicle-identifiers-row">
            <p className="fm-vehicle-identifiers-row-label">Registration</p>
            <p className="fm-vehicle-identifiers-row-value" onClick={this.handleCopy}>{ identifiers.registration || '' }</p>
          </div>
          <div className="fm-vehicle-identifiers-row">
            <p className="fm-vehicle-identifiers-row-label">QR Code</p>
            <p className="fm-vehicle-identifiers-row-value" onClick={this.handleCopy}>{ identifiers.qrCode || '' }</p>
          </div>
          <div className="fm-vehicle-identifiers-row">
            <p className="fm-vehicle-identifiers-row-label">IMEI</p>
            <p className="fm-vehicle-identifiers-row-value" onClick={this.handleCopy}>{ identifiers.imei || '' }</p>
          </div>
          <div className="fm-vehicle-identifiers-row">
            <p className="fm-vehicle-identifiers-row-label">ICCID</p>
            <p className="fm-vehicle-identifiers-row-value" onClick={this.handleCopy}>{ identifiers.iccid || '' }</p>
          </div>
          <div className="fm-vehicle-identifiers-row">
            <p className="fm-vehicle-identifiers-row-label">Serial Number</p>
            <p className="fm-vehicle-identifiers-row-value" onClick={this.handleCopy}>{ identifiers.serialNumber || '' }</p>
          </div>
          <div className="fm-vehicle-identifiers-row">
            <p className="fm-vehicle-identifiers-row-label">IoT Serial Number</p>
            <p className="fm-vehicle-identifiers-row-value" onClick={this.handleCopy}>{ identifiers.iotSerialNumber || '' }</p>
          </div>
          <div className="fm-vehicle-identifiers-row">
            <p className="fm-vehicle-identifiers-row-label">MAC Address</p>
            <p className="fm-vehicle-identifiers-row-value" onClick={this.handleCopy}>{ identifiers.macAddress || '' }</p>
          </div>
          <div className="fm-vehicle-identifiers-row">
            <p className="fm-vehicle-identifiers-row-label">Bluetooth Key</p>
            <p className="fm-vehicle-identifiers-row-value" onClick={this.handleCopy}>{ identifiers.bluetoothKey || '' }</p>
          </div>
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default VehicleIdentifiers;
