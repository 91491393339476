import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NavigationBar from 'components/common/navigation-bar';
import Input from 'components/common/input';
import api from 'helpers/api';
import history from 'helpers/history';
import colors from 'helpers/colors';

class Tickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      query: '',
      searchResults: [],
      tab: props.match.params.tab || 'assigned',
      assigned: [],
      unassigned: [],
      open: [],
    };

    this.loadTickets = this.loadTickets.bind(this);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.search = this.search.bind(this);
    this.renderSearchResult = this.renderSearchResult.bind(this);

    this.handleAssignedTab = this.handleAssignedTab.bind(this);
    this.handleUnassignedTab = this.handleUnassignedTab.bind(this);
    this.handleOpenTab = this.handleOpenTab.bind(this);

    this.renderTicket = this.renderTicket.bind(this);
  }

  componentDidMount() {
    document.title = 'Support Tickets | Flamingo Admin';
    this.loadTickets(this.state.tab);
  }

  /* Networking */

  loadTickets(tab) {
    api.get(`/support/ticket?status=${ tab }&limit=200`)
      .then((res) => this.setState({ [tab]: res.data.data, loading: false }))
      .catch(console.log);
  }

  /* Search */

  handleSearchChange(query) {
    this.setState({ loading: true, query }, () => {
      this.search(query);
    });
  }

  search(query) {
    if (query.length < 2) {
      this.setState({ loading: false });
      return;
    }
    api.post('/support/tickets/search', { query })
      .then((res) => this.setState({ searchResults: res.data.data, loading: false }))
      .catch(console.log);
  }

  /* Controls */

  handleAssignedTab() {
    history.push('/support/assigned');
    this.setState({ tab: 'assigned', loading: this.state.assigned.length === 0 });
    this.loadTickets('assigned');
  }

  handleUnassignedTab() {
    history.push('/support/unassigned');
    this.setState({ tab: 'unassigned', loading: this.state.unassigned.length === 0 });
    this.loadTickets('unassigned');
  }

  handleOpenTab() {
    history.push('/support/open');
    this.setState({ tab: 'open', loading: this.state.open.length === 0 });
    this.loadTickets('open');
  }

  /* Renders */

  renderSearchResult(ticket, i) {
    const backgroundColor = ticket.assignedTo ? (ticket.resolvedAt ? colors.ticket.resolved : colors.ticket.open) : colors.ticket.unassigned;
    return (
      <Link key={i} to={ `/support/ticket/${ticket.id}` } className="fm-support-tickets-ticket">
        <div className="fm-support-tickets-ticket-left">
          <p className="fm-support-tickets-ticket-subject">{ ticket.subject }</p>
          <div className="fm-support-tickets-ticket-left-bottom">
            <div className="fm-support-tickets-ticket-status" style={{ backgroundColor }}></div>
            <p className="fm-support-tickets-ticket-name">{ ticket.name || 'Unknown Name' }</p>
          </div>
        </div>
        <div className="fm-support-tickets-ticket-right">
          <p className="fm-support-tickets-ticket-type" style={{ backgroundColor: colors.support[ticket.source] }}>{ ticket.source }</p>
          <p className="fm-support-tickets-ticket-created">{ moment(ticket.createdAt).fromNow() }</p>
        </div>
      </Link>
    );
  }

  renderTicket(ticket, i) {
    const textItems = (ticket.items || []).filter((t) => ['TEXT', 'NOTE'].includes(t.type));
    const firstItem = textItems[0];
    const preview = firstItem ? firstItem.content : 'No content';
    const backgroundColor = ticket.assignedTo ? (ticket.resolvedAt ? colors.ticket.resolved : colors.ticket.open) : colors.ticket.unassigned;
    const overdue = !ticket.assignedTo && moment().diff(ticket.createdAt, 'hours') >= 24;
    return (
      <Link key={i} to={ `/support/ticket/${ticket.id}` } className="fm-support-tickets-ticket">
        <div className="fm-support-tickets-ticket-left">
          <p className="fm-support-tickets-ticket-subject">{ ticket.subject }</p>
          <div className="fm-support-tickets-ticket-left-bottom">
            <div className="fm-support-tickets-ticket-status" style={{ backgroundColor }}></div>
            <p className="fm-support-tickets-ticket-name">{ ticket.name || 'Unknown Name' }</p>
            <p className="fm-support-tickets-ticket-preview">{ preview }</p>
          </div>
        </div>
        <div className="fm-support-tickets-ticket-right">
          <p className="fm-support-tickets-ticket-type" style={{ backgroundColor: colors.support[ticket.source] }}>{ ticket.source }</p>
          <p className={ 'fm-support-tickets-ticket-created' + (overdue ? ' fm-support-tickets-ticket-overdue' : '') }>{ moment(ticket.createdAt).fromNow() }</p>
        </div>
      </Link>
    );
  }

  render() {
    const { loading, tab, query, searchResults } = this.state;
    const callAction = { to: '/support/call', icon: 'phone-alt' };

    return (
      <div className="fm-tickets">
        <NavigationBar title="Support" loading={loading} rightActions={[callAction]} />
        <div className="fm-tickets-content">
          <div className="fm-tickets-search">
            <Input placeholder="Search support" value={query} onChange={this.handleSearchChange} />
          </div>
          {
            query.length > 0 ? (
              <div className="fm-support-search-results">
                { searchResults.map(this.renderSearchResult) }
              </div>
            ) : (
              <>
                <div className="fm-tickets-tabs">
                  <p onClick={this.handleAssignedTab} className={ tab === 'assigned' ? 'fm-tickets-tab fm-tickets-tab-active' : 'fm-tickets-tab' }>
                    Assigned to Me
                    { this.state.assigned.length > 0 && <span className="fm-tickets-tab-total"> ({this.state.assigned.length})</span> }
                  </p>
                  <p onClick={this.handleUnassignedTab} className={ tab === 'unassigned' ? 'fm-tickets-tab fm-tickets-tab-active' : 'fm-tickets-tab' }>
                    Unassigned
                    { this.state.unassigned.length > 0 && <span className="fm-tickets-tab-total"> ({this.state.unassigned.length})</span> }
                  </p>
                  <p onClick={this.handleOpenTab} className={ tab === 'open' ? 'fm-tickets-tab fm-tickets-tab-active' : 'fm-tickets-tab' }>
                    Open
                    { this.state.open.length > 0 && <span className="fm-tickets-tab-total"> ({this.state.open.length})</span> }
                  </p>
                </div>
                <div className="fm-support-calls">
                  { this.state[tab].map(this.renderTicket) }
                </div>
              </>
            )
          }
        </div>
      </div>
    );
  }
}

export default Tickets;
