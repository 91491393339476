import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Input from 'components/common/input';
import Toast from 'components/common/toast';
import NavigationBar from 'components/common/navigation-bar';
import api from 'helpers/api';

const categories = {
  'es': 'ES',
  'max': 'Max',
  'max-pro': 'Max Pro',
  'max-plus': 'Max Plus',
  'max-plus-x': 'Max Plus X',
  's90l': 'S90L',
  'a200': 'A200',
};

class MaintenanceGuides extends Component {
  constructor(props) {
    super(props);
    this.state = { category: categories[props.match.params.model], guides: [], filteredGuides: [], solutions: [], query: '', loading: true, error: false };

    this.loadGuides = this.loadGuides.bind(this);
    this.loadSolutions = this.loadSolutions.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.renderGuide = this.renderGuide.bind(this);
    this.renderSolution = this.renderSolution.bind(this);
  }

  componentDidMount() {
    document.title = `${this.state.category} Repair Guide | Flamingo Admin`;
    this.loadGuides();
    this.loadSolutions();
  }

  /* NETWORKING */

  loadGuides() {
    this.setState({ loading: true });
    api.get(`/maintenance/guides?model=${this.props.match.params.model}`)
      .then((res) => this.setState({ guides: res.data.data, filteredGuides: res.data.data, loading: false }))
      .catch(this.handleError);
  }

  loadSolutions() {
    this.setState({ loading: true });
    api.get(`/maintenance/solutions?model=${this.props.match.params.model}`)
      .then((res) => this.setState({ solutions: res.data.data }))
      .catch(this.handleError);
  }

  /* HANDLERS */

  handleError(e) {
    const error = window.access(() => e.response.data.code) ? e.response.data.code : 'Something went wrong';
    this.setState({ error, loading: false });
  }

  handleFilterChange(query) {
    const filteredGuides = this.state.guides.filter((guide) => {
      return guide.title.replace(/\W/g, '').toLowerCase().includes(query.replace(/\W/g, '').toLowerCase());
    });
    this.setState({ query, filteredGuides });
  }

  /* RENDERS */

  renderGuide(guide, i) {
    return (
      <Link className="fm-maintenance-guides-item" key={i} to={`/maintenance/guide/${guide.id}`}>
        <div className="fm-maintenance-guides-item-image" style={{ backgroundImage: `url('${guide.thumbnail}')` }}></div>
        <p className="fm-maintenance-guides-item-title">{ guide.title }</p>
      </Link>
    );
  }

  renderSolution(solution, i) {
    return (
      <Link className="fm-maintenance-guides-solution" key={i} to={{ pathname: `/maintenance/solution/${solution.id}`, state: { solution: solution } }}>
        <p className="fm-maintenance-guides-solution-fault">{ solution.fault }</p>
      </Link>
    );
  }

  render() {
    const { category, filteredGuides, query, solutions, loading, error } = this.state;

    return (
      <div className="fm-maintenance-guides">
        <NavigationBar title={`${category} Repair Guides`} loading={loading} showBack={true} />
        <div className="fm-maintenance-guides-search">
          <Input placeholder="Search..." value={query} onChange={this.handleFilterChange} />
        </div>
        {
          solutions.length > 0 &&
          <div className="fm-maintenance-guides-solutions">
            { solutions.map(this.renderSolution) }
          </div>
        }
        <div className="fm-maintenance-guides-items">
          { filteredGuides.map(this.renderGuide) }
        </div>
        { error && <Toast>{error}</Toast> }
      </div>
    );
  }
}

export default MaintenanceGuides;
