import React from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/common/icon';
import user from 'helpers/user';
import auth from 'helpers/auth';

class More extends React.Component {
  constructor() {
    super();
    this.state = { user: { admin: {} } };
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    user.getCurrentUser().then((user) => this.setState({ user }));
  }

  handleLogout() {
    auth.logout();
    user.clearUser();
  }

  render() {
    const { user } = this.state;
    let links = [];
    let isNester = false;
    // Link Builder
    if (user.admin) {
      if (user.admin.isSuper) {
        links = [
          { icon: 'dot-circle', label: 'Vehicles', path: '/vehicles', mobile: true },
          { icon: 'check-square', label: 'Tasks', path: '/task', mobile: true, badgeKey: 'tickets' },
          { icon: 'map-marker', label: isNester ? 'Nests' : 'Hubs', path: isNester ? '/nest' : '/hub', mobile: true },
          { icon: 'tools', label: 'Maintenance', path: '/maintenance/schedule' },
          { icon: 'wave-square', label: 'Statistics', path: '/statistics' },
          { icon: 'life-ring', label: 'Support', path: '/support' },
          { icon: 'flag', label: 'Reports', path: '/report' },
          { icon: 'exclamation-triangle', label: 'Incidents', path: '/incident' },
          { icon: 'user-friends', label: 'Users', path: '/users' },
          { icon: 'user', label: 'Staff', path: '/staff' },
          { icon: 'poll-h', label: 'Team Performance', path: '/performance' },
          { icon: 'calendar', label: 'Team Schedule', path: '/schedule' },
          { icon: 'book-open', label: 'Manuals', path: '/manual' },
          { icon: 'warehouse', label: 'Warehouses', path: '/warehouses' },
          { icon: 'project-diagram', label: 'Analytics', path: '/analytics' },
          { icon: 'search-location', label: 'Trip Search', path: '/trip/search' },
          { icon: 'parking', label: 'Parking', path: '/report/parking' },
          { icon: 'layer-group', label: 'Zones', path: '/zone' },
          { icon: 'hard-hat', label: 'Helmet Selfies', path: '/helmet-selfies' },
          { icon: 'id-card', label: 'Plan Applications', path: '/plan-applications' },
          { icon: 'infinity', label: 'Subscriptions', path: '/subscription' },
          { icon: 'ticket-alt', label: 'Vouchers', path: '/voucher' },
          { icon: 'building', label: 'Insights', path: '/insights' },
          { icon: 'database', label: 'Data Sharing', path: '/data-sharing' },
          { icon: 'battery-full', label: 'Battery Repair', path: '/battery' },
          { icon: 'plus-circle', label: 'Add Vehicle', path: '/vehicle/add' },
        ];
      } else if (user.admin.isManager) {
        // Manager Staff
        links = [
          { icon: 'dot-circle', label: 'Vehicles', path: '/vehicles', mobile: true },
          { icon: 'check-square', label: 'Tasks', path: '/task', mobile: true, badgeKey: 'tickets' },
          { icon: 'map-marker', label: isNester ? 'Nests' : 'Hubs', path: isNester ? '/nest' : '/hub', mobile: true },
          { icon: 'tools', label: 'Maintenance', path: '/maintenance/schedule' },
          { icon: 'wave-square', label: 'Statistics', path: '/statistics' },
          { icon: 'exclamation-triangle', label: 'Incidents', path: '/incident' },
          { icon: 'parking', label: 'Parking', path: '/report/parking' },
          { icon: 'layer-group', label: 'Zones', path: '/zone' },
          { icon: 'user-friends', label: 'Users', path: '/users' },
          { icon: 'user', label: 'Staff', path: '/staff' },
          { icon: 'poll-h', label: 'Team Performance', path: '/performance' },
          { icon: 'calendar', label: 'Team Schedule', path: '/schedule' },
          { icon: 'book-open', label: 'Manuals', path: '/manual' },
          { icon: 'warehouse', label: 'Warehouses', path: '/warehouses' },
          { icon: 'project-diagram', label: 'Analytics', path: '/analytics' },
          { icon: 'search-location', label: 'Trip Search', path: '/trip/search' },
          { icon: 'battery-full', label: 'Battery Repair', path: '/battery' },
          { icon: 'plus-circle', label: 'Add Vehicle', path: '/vehicle/add' },
        ];
      } else if (user.admin.isSupport) {
        // Support Staff
        links = [
          { icon: 'dot-circle', label: 'Vehicles', path: '/vehicles', mobile: true },
          { icon: 'check-square', label: 'Tasks', path: '/task', mobile: true, badgeKey: 'tickets' },
          { icon: 'map-marker', label: isNester ? 'Nests' : 'Hubs', path: isNester ? '/nest' : '/hub', mobile: true },
          { icon: 'life-ring', label: 'Support', path: '/support' },
          { icon: 'flag', label: 'Reports', path: '/report' },
          { icon: 'exclamation-triangle', label: 'Incidents', path: '/incident' },
          { icon: 'parking', label: 'Parking', path: '/report/parking' },
          { icon: 'layer-group', label: 'Zones', path: '/zone' },
          { icon: 'search-location', label: 'Trip Search', path: '/trip/search' },
          { icon: 'hard-hat', label: 'Helmet Selfies', path: '/helmet-selfies' },
          { icon: 'id-card', label: 'Plan Applications', path: '/plan-applications' },
          { icon: 'infinity', label: 'Subscriptions', path: '/subscription' },
          { icon: 'ticket-alt', label: 'Vouchers', path: '/voucher' },
          { icon: 'user-friends', label: 'Users', path: '/users' },
          { icon: 'user', label: 'Staff', path: '/staff' },
          { icon: 'book-open', label: 'Manuals', path: '/manual' },
          { icon: 'warehouse', label: 'Warehouses', path: '/warehouses' },
          { icon: 'calendar', label: 'Team Schedule', path: '/schedule' },
          { icon: 'wave-square', label: 'Statistics', path: '/statistics' },
        ];
      } else {
        // Everyone else
        links = [
          { icon: 'dot-circle', label: 'Vehicles', path: '/vehicles', mobile: true },
          { icon: 'check-square', label: 'Tasks', path: '/task', mobile: true, badgeKey: 'tickets' },
          { icon: 'map-marker', label: isNester ? 'Nests' : 'Hubs', path: isNester ? '/nest' : '/hub', mobile: true },
          { icon: 'tools', label: 'Maintenance', path: '/maintenance/schedule' },
          { icon: 'layer-group', label: 'Zones', path: '/zone' },
          { icon: 'book-open', label: 'Manuals', path: '/manual' },
          { icon: 'warehouse', label: 'Warehouses', path: '/warehouses' },
          { icon: 'wave-square', label: 'Statistics', path: '/statistics' },
          { icon: 'poll-h', label: 'Team Performance', path: '/performance' },
          { icon: 'calendar', label: 'Team Schedule', path: '/schedule' },
          { icon: 'battery-full', label: 'Battery Repair', path: '/battery' },
          { icon: 'plus-circle', label: 'Add Vehicle', path: '/vehicle/add' },
        ];
      }
    }

    let style = {};
    if (user.avatarUrl) {
      style.backgroundImage = `url('${user.avatarUrl}')`
    }
    // Render
    return (
      <div className="fm-more">
        <div className="fm-more-profile">
          <div className="fm-more-profile-avatar" style={style}></div>
          <div className="fm-more-profile-details">
            <p className="fm-more-profile-name">{ user.firstName } { user.lastName }</p>
            <p className="fm-more-profile-region">{ user.admin.region ? user.admin.region.name : 'Flamingo' }</p>
          </div>
        </div>
        <div className="fm-more-navigation">
          { links.map((link, i) => <Link key={i} className="fm-more-link" to={link.path} onClick={this.handleClick}><Icon icon={link.icon} />{link.label}</Link>) }
        </div>
        <div className="fm-more-extra">
          { user.admin.canChangeRegion ? <Link className="fm-more-link" to="/change-region" onClick={this.handleClick}><Icon icon="location-arrow" />Change Region</Link> : null }
          <Link className="fm-more-link" to="/login" onClick={this.handleLogout}><Icon icon="sign-out-alt" />Logout</Link>
        </div>
      </div>
    );
  }
}

export default More;
